import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import { getToken } from "@/utils/auth"
import { info } from "@/api/member/info"
import { orderNum, couponNum } from '@/api/member/index'

import { addressList } from "@/api/member/member"

export default {
	name: "personalIndex",
	components: {
		Swiper,
		SwiperSlide
	},
	data: () => {
		return {
			Default: 1,
			wishlistOption: {
				slidesPerView: 3,
				spaceBetween: 10,
				slidesPerGroup: 3,
				loop: true,
				pagination: {
					el: '.swiper-pagination-1',
					clickable: true, // 允许点击小圆点跳转
				}
			},
			wishlistList: [
				{
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}
			],
			footprintOption: {
				pagination: {
					el: '.swiper-pagination-2',
					clickable: true,
				},
				slidesPerView: 3,
				slidesPerGroup: 3,
				spaceBetween: 10,
				loop: true
			},
			footprintList: [
				{
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}, {
					imgUrl: require('@/assets/images/personal/tu.png'),
				}
			],
			userName: "",
			address_list: [],
			addressEmpty: false,
			addressFormList: {},
            addressTitle: "",
            addressForm: false,
            deleteAddress: false,
			orderNumber: {
				refunding: 0,
				waitconfirm: 0,
				waitpay: 0,
				waitrate: 0,
				waitsend: 0
			},
			couponNumber: 0,
			// 需要翻译的静态文字
            translateTextData: {
				My_Account: 'My Account',
				Coupons: 'Coupons',
				My_Orders: 'My Orders',
				See_more: 'See more',
				Unpaid: 'Unpaid',
				Processing: 'Processing',
				Shipped: 'Shipped',
				Returns: 'Returns',
				Shipping_Address: 'Shipping Address',
				
            }
		}
	},
	watch: {

	},
	created() {
		this.getUserName();
		this.getMemberAddress();
		this.getCouponNum();
		this.getOrderNum();
		this.getTranslateText();
	},
	mounted() {
		// this.swiper.slideTo(1, 1000, false)
		// this.myWishlist.slideTo(3, 1000, false)
		// this.getDiyviewInfo();
	},
	computed: {
		swiper() {
			return this.$refs.wishlistSwiper.swiper
		}
	},
	methods: {
		// 翻译静态文本
        getTranslateText() {
            let strText = '';
            let i = 0;
            let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
            translateData.forEach((item,index) => {
                strText += item + '\n'
            })
            // 翻译文本
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    for(let key in this.translateTextData){
                        this.translateTextData[key] = resData[i];
                        i++;
                    }
                }
            })
        },
		getCouponNum() {
			couponNum().then( res =>{
				if(res.code == 0 && res.data) {
					this.couponNumber = res.data;
				}
			})
		},
		getOrderNum() {
			orderNum({ order_status: "waitpay,waitsend,waitconfirm,waitrate,refunding" }).then( res => {
				if(res.code == 0 && res.data){
					this.orderNumber = res.data;
				}
			}).catch( err => {
				// console.log(err)
			})
		},
		addClick() {
            this.addressTitle = "Add New Address";
            this.addressForm = true;
            this.ative = 0;
            this.addressFormList = [];
        },
        editClick(i) {
            this.addressTitle = "Edit Address";
            this.addressForm = true;
            this.ative = 1;
            this.addressFormList = this.address_list[i];
        },
		deleteAddressClick(i) {
            this.ids = this.address_list[i].id;
            this.deleteAddress = true;
        },
		handleClose1() {
            this.deleteAddress = false;
        },
		handleClose() {
            this.addressForm = false;
            this.getMemberAddress();
            // this.$forceUpdate();
        },
		deleteNo(val) {
            this.deleteAddress = val;
        },
        deleteYes(val) {
            this.deleteAddress = val;
            this.getMemberAddress();
        },
		//获取收货地址
        getMemberAddress() {
            addressList({
                page_size: 2
            }).then(res => {
                const {
                    data
                } = res
                if (data && data.list) {
					// for(let i = 0; i < data.list.length;)
                    this.address_list = data.list;
					if(this.address_list.length < 2){
						this.addressEmpty = true;
					}else{
						this.addressEmpty = false;
					}
                }
            }).catch(err => {
                const {
                    code,
                    message,
                    data
                } = err
                this.$message.error(message)
            })
        },
		getUserName() {
            let token = getToken();
            if(token){
                info({ token: token }).then( res => {
                    if(res.code == 0 && res.data){
                        this.userName = res.data.nickname;
                    }
                }).catch( err => {
                    // console.log(err)
                })
            }else{
                this.userName = "";
            }
        },
		orderClick() {
			this.$router.push({ path: '/personal/myOrders' });
		},
		
	}
}
