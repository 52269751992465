<template>
    <div class="personal-index">
        <div class="personal-main-middle">
            <div class="middle-top">
                <div class="main-title">
                    <span>Hi ! {{userName}}</span>
                    <router-link to="/personal/myAccount">{{translateTextData.My_Account}}<img src="@/assets/images/personal/r.png" /></router-link>
                </div>
                <div class="middle-top-main">
                    <router-link to="/personal/coupons">
                        <span>{{couponNumber}}</span>
                        <p>{{translateTextData.Coupons}}</p>
                    </router-link>
                    <!-- <router-link to="">
                        <span>160</span>
                        <p>Reward Points</p>
                    </router-link>
                    <router-link to="">
                        <span>$189.00</span>
                        <p>Wallet</p>
                    </router-link> -->
                </div>
            </div>
            <div class="middle-bottom">
                <div class="middle-bottom-title" style="cursor: pointer;" @click="orderClick">
                    <span>{{translateTextData.My_Orders}}</span>
                    <p>{{translateTextData.See_more}}<img src="@/assets/images/personal/r.png" /></p>
                </div>
                <div class="middle-bottom-main">
                    <router-link to="/personal/myOrders?type=1">
                        <img src="@/assets/images/personal/unpaid.png" />
                        <div>
                            <p>{{translateTextData.Unpaid}}</p>
                            <span v-if="orderNumber.waitpay > 0">{{orderNumber.waitpay}}</span>
                        </div>
                    </router-link>
                    <router-link to="/personal/myOrders?type=2">
                        <img src="@/assets/images/personal/processing.png" />
                        <div>
                            <p>{{translateTextData.Processing}}</p>
                            <span v-if="orderNumber.waitsend > 0">{{orderNumber.waitsend}}</span>
                        </div>
                    </router-link>
                    <router-link to="/personal/myOrders?type=3">
                        <img src="@/assets/images/personal/shipped.png" />
                        <div>
                            <p>{{translateTextData.Shipped}}</p>
                            <span v-if="orderNumber.waitconfirm > 0">{{orderNumber.waitconfirm}}</span>
                        </div>
                    </router-link>
                    <router-link to="/personal/myOrders?type=5">
                        <img src="@/assets/images/personal/return.png" />
                        <div>
                            <p>{{translateTextData.Returns}}</p>
                            <span v-if="orderNumber.refunding > 0">{{orderNumber.refunding}}</span>
                        </div>
                    </router-link>
                </div>
                <div class="middle-bottom-banner"><img src="@/assets/images/personal/banner.png" /></div>
            </div>
        </div>
        <div class="personal-main-right">
            <div class="right-top">
                <div class="main-title" style="border-bottom: none">
                    <div>
                        <img src="@/assets/images/personal/address.png" />
                        <span>{{translateTextData.Shipping_Address}}</span>
                    </div>
                    <router-link to="/personal/address">{{translateTextData.See_more}}<img src="@/assets/images/personal/r.png" /></router-link>
                </div>
                <div class="right-top-main" v-for="(item, index) in address_list" :key="index">
                    <h5>{{ item.name }}</h5>
                    <span>{{ item.telephone }}</span>
                    <p>{{ item.full_address }}</p>
                    <div>
                        <el-radio v-if="item.is_default != 1" v-model="Default" :label="index + 2" >Default</el-radio>
                        <el-radio v-else-if="item.is_default == 1" v-model="Default" :label="item.is_default" >Default</el-radio>
                        <div>
                            <img src="@/assets/images/personal/delet.png" @click="deleteAddressClick(index)" />
                            <img src="@/assets/images/personal/edit.png" @click="editClick(index)" />
                        </div>
                    </div>
                </div>
                <div class="right-top-add" v-show="addressEmpty" @click="addClick">
                    <h5>+ </h5>
                </div>
            </div>
            <!-- 收藏 -->
            <!-- <div class="right-bottom">
                <div class="main-title">
                    <div>
                        <img src="@/assets/images/personal/wishlist.png" />
                        <span>Wishlist</span>
                    </div>
                    <router-link to="/personal/wishlist">See more<img src="@/assets/images/personal/r.png" /></router-link>
                </div>
                <div class="swiper">
                    <swiper :options="wishlistOption" ref="wishlistSwiper">
                        <swiper-slide v-for="(item, index) in wishlistList" :key="index">
                            <img :src="item.imgUrl" />
                        </swiper-slide>
                        <div class="swiper-pagination-1" style="text-align: center" slot="pagination"></div>
                    </swiper>
                </div>
            </div> -->
            <!-- 足迹 -->
            <!-- <div class="right-bottom">
                <div class="main-title">
                    <div>
                        <img src="@/assets/images/personal/wishlist(1).png" />
                        <span>Footprint</span>
                    </div>
                    <router-link to="/personal/footprint">See more<img src="@/assets/images/personal/r.png" /></router-link>
                </div>
                <div class="swiper">
                    <swiper :options="footprintOption" ref="myFootprint">
                        <swiper-slide v-for="(item, index) in footprintList" :key="index">
                            <img :src="item.imgUrl" />
                        </swiper-slide>
                        <div class="swiper-pagination-2" style="text-align: center" slot="pagination"></div>
                    </swiper>
                </div>
            </div> -->
        </div>

        <el-dialog :title="addressTitle" :visible.sync="addressForm" @closed="handleClose">
            <AddressForm :addressAtive="ative" :addressList="addressFormList" @closeDialog="handleClose"></AddressForm>
        </el-dialog>
        <el-dialog :visible.sync="deleteAddress" @closed="handleClose1" width="23%" style="margin-top: 20vh">
            <DeleteAddress @deteleNO="deleteNo" @deteleYes="deleteYes" :ids="ids"></DeleteAddress>
        </el-dialog>
    </div>
</template>

<style lang="scss" scoped>
.personal-index {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .main-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0 20px;
        border-bottom: 1px solid #eceeee;

        > div:first-child {
            display: flex;
            align-items: center;

            img {
                margin-right: 9px;
            }
        }

        span {
            font-size: 18px;
            font-family: Montserrat,SF-UI;
            font-weight: 600;
            color: #330000;
        }
        a {
            font-size: 14px;
            font-family: Montserrat,SF-UI;
            font-weight: 400;
            color: #96989a;
            cursor: pointer;

            img {
                margin-left: 8px;
            }
        }
    }

    .personal-main-middle {
        width: 57.95%;
        display: flex;
        flex-direction: column;

        .middle-top {
            width: calc(100% - 40px);
            padding: 0 20px;
            background: #fff;

            .middle-top-main {
                width: 100%;
                display: flex;
                justify-content: space-around;

                a {
                    padding: 60px 0 50px;
                    text-align: center;

                    span {
                        font-size: 16px;
                        font-family: Montserrat,SF-UI;
                        font-weight: Bold;
                        color: #330000;
                    }
                    p {
                        font-size: 14px;
                        font-family: Montserrat,SF-UI;
                        font-weight: 400;
                        color: #68696a;
                    }
                }
            }
        }

        .middle-bottom {
            margin-top: 20px;
            width: calc(100% - 40px);
            padding: 0 20px;
            background: #fff;

            .middle-bottom-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 0 20px;
                border-bottom: 1px solid #eceeee;

                > span {
                    font-size: 18px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 600;
                    color: #330000;
                }
                p {
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 400;
                    color: #96989a;
                    cursor: pointer;

                    img {
                        margin-left: 8px;
                    }
                }
            }

            .middle-bottom-main {
                width: 100%;
                display: flex;
                justify-content: space-around;

                a {
                    padding: 60px 0 50px;
                    text-align: center;

                    >div{
                        position: relative;

                        p {
                            font-size: 14px;
                            font-family: Montserrat,SF-UI;
                            font-weight: 400;
                            color: #68696a;
                            padding-top: 10px;
                        }

                        span{
                            position: absolute;
                            top: -45px;
                            right: 0;
                            border-radius: 50%;
                            background: #ff5656;
                            width: 24px;
                            height: 24px;
                            font-size: 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                        }
                    }
                    
                }
            }

            .middle-bottom-banner {
                padding: 60px 20px 20px;
            }
        }
    }

    .personal-main-right {
        width: calc(100% - 57.95% - 20px);

        .right-top {
            width: calc(100% - 40px);
            padding: 0 20px 20px;
            background: #fff;

            .right-top-main:last-child {
                margin-bottom: 0;
            }
            .right-top-main {
                width: calc(100% - 42px);
                padding: 20px;
                border: 1px solid #d8dadc;
                margin-bottom: 15px;

                h5 {
                    font-size: 16px;
                    font-family: Montserrat,SF-UI;
                    font-weight: bold;
                    color: #330000;
                }

                span {
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 400;
                    color: #330000;
                }

                p {
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 300;
                    color: #68696a;
                    line-height: 24px;
                }

                > div {
                    padding-top: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > div {
                        display: flex;

                        > img {
                            cursor: pointer;
                        }
                        img:first-child {
                            padding-right: 30px;
                        }
                    }
                }
            }

            .right-top-add {
                width: calc(100% - 42px);
                padding: 20px;
                border: 1px solid #d8dadc;
                text-align: center;
                cursor: pointer;

                h5{
                    font-size: 20px;
                    font-family: Montserrat,SF-UI;
                    font-weight: bold;
                    color: #330000;
                }
            }
        }

        .right-bottom {
            width: calc(100% - 40px);
            margin-top: 20px;
            padding: 0 20px 20px;
            background: #fff;

            .main-title {
                border-bottom: none;
            }
        }
    }
}
</style>

<script>
import personalIndex from "../_personal/index.js"

import AddressForm from "./alert/addressForm"
import DeleteAddress from "./alert/deleteAddress"

export default {
    name: "personalIndex",
    components: {
        AddressForm,
        DeleteAddress
    },
    mixins: [personalIndex]
}
</script>